import React from 'react'

function CompleteGuideMigratingWordpressToAem() {
    return (
        <div style={{height: "100vh"}}>
            <object data="../../complete-guide-migrating-wordpress-to-aem.pdf#toolbar=0" type="application/pdf" width="100%" height="100%">
                <a href="../../complete-guide-migrating-wordpress-to-aem.pdf"></a>
            </object>
        </div>
    )
}

export default CompleteGuideMigratingWordpressToAem
